import type { ReactNode } from 'react';

export interface SubNavRoute {
  to?: string;
  href?: string;
  toSubMarket?: string;
  anchor?: string;
  onClick?: (id?: string | number) => void;
  id?: string | number;
  label: string | ReactNode;
  payload?: ReducerActionPayload;
  tracking?: string;
  rel?: string;
}

export const ACTION_ROUTE_THUNK_AWAIT = '@ROUTE/THUNK-AWAIT';
export const ACTION_ROUTE_CHANGE_COMPLETE = '@ROUTE/CHANGE-COMPLETE';

export const ROUTE_COMPANY = '@ROUTE/COMPANY';
export const ROUTE_LOCALE_COMPANY = '@ROUTE/LOCALE_COMPANY';
export const ROUTE_COMPANY_WITH_EVENTS = '@ROUTE/COMPANY-WITH-EVENTS';
export const ROUTE_LOCALE_COMPANY_WITH_EVENTS =
  '@ROUTE/LOCALE_COMPANY-WITH-EVENTS';
export const ROUTE_COMPARE_COMPANY = '@ROUTE/COMPARE-COMPANY';
export const ROUTE_COMPARE_HOME = '@ROUTE/COMPARE-COMPANY-HOME';
export const ROUTE_CREATE_CRITERIA = '@ROUTE/CREATE-CRITERIA';
export const ROUTE_HOME = '@ROUTE/HOME';
export const ROUTE_OOPS = '@ROUTE/OOPS';
export const ROUTE_PORTFOLIO = '@ROUTE/PORTFOLIO';
export const ROUTE_PORTFOLIO_NEW = '@ROUTE/PORTFOLIO-NEW';
export const ROUTE_PORTFOLIO_TRANSACTIONS_CREATE =
  '@ROUTE/PORTFOLIO-TRANSACTIONS-CREATE';
export const ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO =
  '@ROUTE/PORTFOLIO-TRANSACTIONS-DISCARD-PORTFOLIO';
export const ROUTE_PORTFOLIO_TRANSACTIONS_EDIT =
  '@ROUTE/PORTFOLIO-TRANSACTIONS-EDIT';
export const ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS =
  '@ROUTE/PORTFOLIO-TRANSACTIONS-SAVE-SUCCESS';
export const ROUTE_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING =
  '@ROUTE/PORTFOLIO-TRANSACTIONS-EDIT-HOLDING';
export const ROUTE_PORTFOLIO_DEMO = '@ROUTE/PORTFOLIO-DEMO';
export const ROUTE_PORTFOLIO_PUBLIC = '@ROUTE/PORTFOLIO-PUBLIC';
export const ROUTE_PORTFOLIO_DEFAULT = '@ROUTE/PORTFOLIO-DEFAULT';
export const ROUTE_PORTFOLIO_LINKING_SUCCESS =
  '@ROUTE/ROUTE_PORTFOLIO_LINKING_SUCCESS';
export const ROUTE_DEPRECATED_PORTFOLIO_LIST =
  '@ROUTE/DEPRECATED-PORTFOLIO-LIST';
export const ROUTE_DEPRECATED_IMPORT_PORTFOLIO =
  '@ROUTE/DEPRECATED-IMPORT-PORTFOLIO';
export const ROUTE_DEPRECATED_PORTFOLIO = '@ROUTE/DEPRECATED-PORTFOLIO';
export const ROUTE_DEPRECATED_PUBLIC_PORTFOLIO =
  '@ROUTE/DEPRECATED-PUBLIC-PORTFOLIO';
export const ROUTE_DEPRECATED_PORTFOLIO_HOME =
  '@ROUTE/DEPRECATED-PORTFOLIO-HOME';
export const ROUTE_DEPRECATED_PORTFOLIO_EDIT =
  '@ROUTE/DEPRECATED-PORTFOLIO-EDIT';
export const ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN =
  '@ROUTE/DEPRECATED-PORTFOLIO-EDIT-TXN';
export const ROUTE_DEPRECATED_PORTFOLIO_SHARE =
  '@ROUTE/DEPRECATED-PORTFOLIO-SHARE';
export const ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT =
  '@ROUTE/DEPRECATED-PORTFOLIO-RETURNS-REPORT';
export const ROUTE_MY_PORTFOLIOS = '@ROUTE/MY-PORTFOLIOS';
export const ROUTE_PRICE_CHART = '@ROUTE/PRICE-CHART';
export const ROUTE_VIEW_CRITERIA = '@ROUTE/VIEW-CRITERIA';
export const ROUTE_DISCOVER_INVESTING_IDEAS = '@ROUTE/DISCOVER-INVESTING-IDEAS';
export const ROUTE_DISCOVER_INVESTING_IDEAS_MARKET =
  '@ROUTE/DISCOVER-INVESTING-IDEAS-MARKET';
export const ROUTE_INVESTING_IDEA_HOME = '@ROUTE/INVESTING-IDEA';
export const ROUTE_INVESTING_IDEA_MARKET = '@ROUTE/INVESTING-IDEA-MARKET';
export const ROUTE_INVESTING_IDEA_MARKET_INDUSTRY =
  '@ROUTE/INVESTING-IDEA-MARKET-INDUSTRY';
export const ROUTE_COMMUNITY_IDEA = '@ROUTE/COMMUNITY-IDEA';
export const ROUTE_DISCOVER_SAMPLE_PORTFOLIOS = '@ROUTE/EXPLORE-PORTFOLIOS';
export const ROUTE_VIEW_GRID = '@ROUTE/VIEW-GRID';
export const ROUTE_WELCOME = '@ROUTE/WELCOME';
export const ROUTE_USER_PROFILE = '@ROUTE/USER-PROFILE';
export const ROUTE_USER_NOTIFICATIONS = '@ROUTE/USER-NOTIFICATIONS';
export const ROUTE_USER_PLAN = '@ROUTE/USER-PLAN';
export const ROUTE_USER_SUBSCRIPTION = '@ROUTE/USER-SUBSCRIPTION';
export const ROUTE_USER_SUBSCRIPTION_AND_BILLING =
  '@ROUTE/USER-SUBSCRIPTION-AND-BILLING';
export const ROUTE_USER_BETA_LAB = '@ROUTE/USER-BETA-LAB';
export const ROUTE_USER_LOGOUT = '@ROUTE/USER-LOGOUT';
export const ROUTE_USER_REGISTER = '@ROUTE/USER-REGISTER';
export const ROUTE_ACCOUNT_RECOVERY = '@ROUTE/ACCOUNT-RECOVERY';
export const ROUTE_OTP_LOGIN = '@ROUTE/OTP-LOGIN';
export const ROUTE_DELETE_ACCOUNT = '@ROUTE/ACCOUNT-DEACTIVATE';

export const ROUTE_STOCKS_HOME = '@ROUTE/STOCKS-HOME';
export const ROUTE_STOCKS_MARKET = '@ROUTE/STOCKS-MARKET';
export const ROUTE_STOCKS_MARKET_INDUSTRY = '@ROUTE/STOCKS-MARKET-INDUSTRY';

export const ROUTE_STOCKS = '@ROUTE/STOCKS';
export const ROUTE_EDIT_USER_IDEA = '@ROUTE/editUserIdea';
export const ROUTE_EDIT_EXPLORE_IDEA = '@ROUTE/editExploreIdea';
export const ROUTE_MAINTENANCE = '@ROUTE/MAINTENANCE';
export const ROUTE_PLANS = '@ROUTE/PLANS';
export const ROUTE_DASHBOARD = '@ROUTE/DASHBOARD';
export const ROUTE_DASHBOARD_WITH_EVENTS = '@ROUTE/DASHBOARD-WITH-EVENTS';
export const ROUTE_ABOUT = '@ROUTE/ABOUT';
export const ROUTE_CAREERS = '@ROUTE/CAREERS';
export const ROUTE_WATCHLIST = '@ROUTE/WATCHLIST';
export const ROUTE_WATCHLIST_WITH_EVENTS = '@ROUTE/WATCHLIST-WITH-EVENTS';
export const ROUTE_PODCASTS = '@ROUTE/PODCASTS';
export const ROUTE_TERMS_AND_CONDITIONS = '@ROUTE/TERMS-AND-CONDITIONS';
export const ROUTE_TERMS_AND_CONDITIONS_FULL =
  '@ROUTE/TERMS-AND-CONDITIONS-FULL';
export const ROUTE_PARTNER_CLAIM = '@ROUTE/PARTNER-CLAIM';
export const ROUTE_PARTNER_REGISTER = '@ROUTE/PARTNER-REGISTER';
export const ROUTE_PARTNER_LOGIN = '@ROUTE/PARTNER-LOGIN';
export const ROUTE_PARTNER_ERROR = '@ROUTE/PARTNER-ERROR';
export const ROUTE_PARTNER_AVANZA_CLAIM = '@ROUTE/PARTNER-AVANZA-CLAIM';
export const ROUTE_PARTNER_AVANZA_REGISTER = '@ROUTE/PARTNER-AVANZA-REGISTER';
export const ROUTE_PARTNER_AVANZA_LOGIN = '@ROUTE/PARTNER-AVANZA-LOGIN';
export const ROUTE_PARTNER_AVANZA_ERROR = '@ROUTE/PARTNER-AVANZA-ERROR';
export const ROUTE_PRIVACY_POLICY = '@ROUTE/PRIVACY-POLICY';
export const ROUTE_PRIVACY_POLICY_FULL = '@ROUTE/PRIVACY-POLICY-FULL';
export const ROUTE_ARTICLE = '@ROUTE/ARTICLE';
export const ROUTE_MARKET_HUB = '@ROUTE/MARKET-HUB';
export const ROUTE_MARKET_HOME = '@ROUTE/MARKET-HOME';
export const ROUTE_MARKET = '@ROUTE/MARKET';
export const ROUTE_MARKET_SECTOR = '@ROUTE/MARKET-SECTOR';
export const ROUTE_MARKET_SECTOR_SECONDARY = '@ROUTE/MARKET-SECTOR-SECONDARY';
export const ROUTE_MARKET_SECTOR_SECONDARY_TERTIARY =
  '@ROUTE/MARKET-SECTOR-SECONDARY-TERTIARY';
export const ROUTE_USER_UNSUBSCRIBE = '@ROUTE/USER-UNSUBSCRIBE';
export const ROUTE_AFFILIATE_PROGRAM = '@ROUTE/AFFILIATE-PROGRAM';
export const ROUTE_BUSINESS = '@ROUTE/BUSINESS';
export const ROUTE_ACCOUNT_VALIDATION = '@ROUTE/ACCOUNT-VALIDATION';
export const ROUTE_PRO_API = '@ROUTE/PRO-API';
export const ROUTE_NARRATIVE = '@ROUTE/NARRATIVE';
export const ROUTE_NARRATIVE_COMMUNITY_DETAIL =
  '@ROUTE/NARRATIVE-COMMUNITY-DETAIL';
export const ROUTE_PUBLIC_NARRATIVE_CREATE = '@ROUTE/PUBLIC_NARRATIVE_CREATE';
export const ROUTE_PUBLIC_NARRATIVE_EDIT = '@ROUTE/PUBLIC_NARRATIVE_EDIT';
export const ROUTE_NARRATIVE_COMMUNITY = '@ROUTE/NARRATIVE-COMMUNITY';
export const ROUTE_NARRATIVE_COMMUNITY_PROFILE =
  '@ROUTE/NARRATIVE-COMMUNITY-PROFILE';
export const ROUTE_SUBSCRIPTION_EXTENSION = '@ROUTE/SUBSCRIPTION-EXTENSION';
export const ROUTE_PASSWORD_RESET = '@ROUTE/PASSWORD-RESET';
export const ROUTE_SOCIAL_TO_LOCAL = '@ROUTE/SOCIAL-TO-LOCAL';
export const ROUTE_EMAIL_CHANGE = '@ROUTE/EMAIL-CHANGE';
export const ROUTE_GIFT_CARD_REDEEM = '@ROUTE/GIFT-CARD-REDEEM';
export const ROUTE_GIFT_CARD_PURCHASE = '@ROUTE/GIFT-CARD-PURCHASE';
export const ROUTE_PORTFOLIO_HOLDINGS = '@ROUTE/PORTFOLIO-HOLDINGS';
export const ROUTE_PORTFOLIO_ANALYSIS = '@ROUTE/PORTFOLIO-ANALYSIS';
export const ROUTE_PORTFOLIO_DIVIDENDS = '@ROUTE/PORTFOLIO-DIVIDENDS';
export const ROUTE_PORTFOLIO_NARRATIVES = '@ROUTE/PORTFOLIO-NARRATIVES';
export const ROUTE_PORTFOLIO_RETURNS = '@ROUTE/PORTFOLIO-RETURNS';

export const QUERYSTRING_SALES_SITE = '/?view';
export const URL_SALES_SITE = `https://simplywall.st${QUERYSTRING_SALES_SITE}`;
